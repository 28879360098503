import API from '@/services/api/api';

const sendLinkToScheduleEvent = async (tenantId: string, eventId: number, link: string) => {
  try {
    const response = await API.post(`/programs/schedule/link?TenantId=${tenantId}&EventId=${eventId}&Link=${link}`);
    return response;
  } catch (e) {
    return e.response ? Promise.reject(e.response) : e;
  }
};

export { sendLinkToScheduleEvent };
