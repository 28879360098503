<template>
  <v-dialog :max-width="650" :value="value" persistent>
    <v-card :loading="loading ? 'grey darken-4' : false" :disabled="disabled">
      <v-toolbar dark color="grey lighten-3" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text"> {{ title }} </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4 black--text">
        <div v-html="message"></div>
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <btn type="submit" @click="confirmAction" color="primary">Confirm</btn>
        <btn @click="cancelAction">Cancel</btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';
  import { Btn } from '../Ui';
  import { mapActions } from 'vuex';
  import { AUTO_PUBLISH_LITERALS, PROGRAMS_STATUS } from '@/constants/programs';
  import { publishProgramToClientPublications } from '@/utils/publications';
  import { sendLinkToScheduleEvent } from '@/utils/schedules';
  import { DigitalProgram } from '@/store/programs/types';

  export default Vue.extend({
    name: 'ConfirmPublishDialog',
    components: { Btn },
    props: {
      value: { type: Boolean, default: false },
      title: { type: String, default: 'Confirm Action' },
      message: { type: String, default: '' },
      program: { type: DigitalProgram, required: true },
    },
    data() {
      return {
        loading: false,
        disabled: false,
      };
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      ...mapActions('programs', { changeProgramStatus: 'changeStatus' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      confirmAction() {
        this.disabled = true;
        this.loading = true;
        this.onPublishAction();
      },
      cancelAction() {
        this.$emit('cancel');
        this.closeModal();
      },
      closeModal() {
        this.loading = false;
        this.disabled = false;
        this.$emit('input', false);
      },
      async onPublishAction() {
        const { clientId, id: programId, status: oldStatus } = this.program;

        let successMessages: string[] = [];
        let failureMessages: string[] = [];

        // set digital program status to published
        try {
          await this.changeProgramStatus({
            id: programId,
            status: PROGRAMS_STATUS.PUBLISHED,
            oldStatus: oldStatus,
          });
          successMessages.push('The program has been published');
        } catch (error) {
          const msg = error?.response?.data || 'Could not publish program';
          this.closeModal();
          this.showToast(msg);
          return;
        }

        const { tenantId } = await this.fetchClient(clientId);
        const { clientSportId } = this.program.sport;
        const sport = clientSportId ? await this.fetchSport({ clientId, clientSportId }) : null;
        const sportName = sport ? sport.shortName : 'programs';
        const programUrl = `${config.frontendUrl}/${tenantId}/${sportName}/${programId}/`;
        const { scheduleId: eventId } = this.program.event;

        // auto-publish digital program to school's publications page
        try {
          const publishToSchoolResult = await publishProgramToClientPublications(clientId, programId, programUrl);
          if (publishToSchoolResult === true) {
            successMessages.push(AUTO_PUBLISH_LITERALS.toast.publicationsPage.success);
          } else {
            failureMessages.push(AUTO_PUBLISH_LITERALS.toast.publicationsPage.failure);
          }
        } catch (error) {
          failureMessages.push(AUTO_PUBLISH_LITERALS.toast.publicationsPage.failure);
          console.error(error);
        }

        // auto-publish digital program link to the event's game program field
        if (eventId > 0) {
          try {
            const publishToGameProgramLink = await sendLinkToScheduleEvent(tenantId, eventId, programUrl);
            if (publishToGameProgramLink.status == 200) {
              successMessages.push(AUTO_PUBLISH_LITERALS.toast.gameProgramLink.success);
            } else {
              failureMessages.push(AUTO_PUBLISH_LITERALS.toast.gameProgramLink.failure);
            }
          } catch (error) {
            failureMessages.push(AUTO_PUBLISH_LITERALS.toast.gameProgramLink.failure);
            console.error(error);
          }
        }

        let toastMessage = '';

        for (let index = 0; index < successMessages.length; index++) {
          const element = successMessages[index];

          const prepend = index == 0 ? '' : index == successMessages.length - 1 ? ' and ' : ', ';
          const append = index == successMessages.length - 1 ? ' successfully' : '';

          toastMessage += `${prepend}${element}${append}`;
        }

        for (let index = 0; index < failureMessages.length; index++) {
          const element = failureMessages[index];

          const prepend =
            index == 0
              ? ', but was unable to be added to the '
              : index == failureMessages.length - 1
              ? ' and the '
              : ', ';

          toastMessage += `${prepend}${element}`;
        }

        if (failureMessages.length > 0) {
          toastMessage += `. ${AUTO_PUBLISH_LITERALS.toast.contactSupport}`;
        }

        this.$emit('confirm');
        this.closeModal();
        this.showToast(toastMessage);
      },
    },
  });
</script>
